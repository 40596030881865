var preloadSupport;
// start polyfill section
(function (w) {
  try {
    preloadSupport = w
      .document
      .createElement("link")
      .relList
      .supports("preload");
  } catch (e) {
    preloadSupport = false;
  }
  return function () {
    return preloadSupport;
  };
}(typeof global !== "undefined" ?
  global :
  this));

/**
 * ChildNode.after() polyfill
 * Adapted from https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/after()/after().md
 * @author Chris Ferdinandi
 * @license MIT
 */
(function (elem) {

  // Check if element is a node
  // https://github.com/Financial-Times/polyfill-service
  var isNode = function (object) {

    // DOM, Level2
    if (typeof Node === 'function') {
      return object instanceof Node;
    }

    // Older browsers, check if it looks like a Node instance)
    return object &&
      typeof object === "object" &&
      object.nodeName &&
      object.nodeType >= 1 &&
      object.nodeType <= 12;

  };

  // Add after() method to prototype
  for (var i = 0; i < elem.length; i++) {
    if (!window[elem[i]] || 'after' in window[elem[i]].prototype) continue;
    window[elem[i]].prototype.after = function () {
      var argArr = Array.prototype.slice.call(arguments);
      var docFrag = document.createDocumentFragment();

      for (var n = 0; n < argArr.length; n++) {
        docFrag.appendChild(isNode(argArr[n]) ? argArr[n] : document.createTextNode(String(argArr[n])));
      }

      this.parentNode.insertBefore(docFrag, this.nextSibling);
    };
  }

})(['Element', 'CharacterData', 'DocumentType']);

// end polyfill section

function loadJS(u, a, include_pos) {
  var r;
  var s = document.createElement("script");
  s.src = u;
  a ? s.setAttribute(a, a) : null;
  if (include_pos === 'head' && include_pos !== undefined) {
    r = document.getElementsByTagName("script")[0];
    r.parentNode.insertBefore(s, r);
  } else {    
      r = document.querySelector("body");
      r.after(s);    
  }
}
if (!window.HTMLPictureElement || !('sizes' in document.createElement('img'))) {
  loadJS(base_Path + "/js/respimg.min.js", "async", 'head');
  loadJS(base_Path + "/js/picturefill.min.js", "async", 'head');
}
// window.onload = function () {
// loadJS("https://www.apexchat.net/scripts/invitation.ashx?company=klinespecter", "defer");
// loadJS(base_Path + "/js/yt-player.js", "defer");
// }
function loadLink(u, pre) {
  var s = document.getElementsByTagName('link')[0],
    link = document.createElement("link");
  if (pre === "preload") {
    link.rel = pre;
    link.as = "font";
    link.href = u;
    link.type = "font/woff2";
    link.setAttribute("crossorigin", "anonymous");
    link.media = "all";
  }
  s.parentNode.insertBefore(link, s);
}

if (preloadSupport) {
  loadLink((base_Path + "/fonts/fontawesome-webfont.woff2"), "preload");
  
}